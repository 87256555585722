import { doc, updateDoc, onSnapshot, query, collection, where, addDoc, setDoc, getDoc } from "firebase/firestore";
import Auth from "./Auth";
import fbdb from "./fbdb";
import { setUserProfile } from "../redux/UserStateSlice";
import { formatDocData } from "../utils";
import { removeDatabyId, setDatabyId, setDirectory } from "../redux/Project/ProjectSlice";

export function subsUserProfile(dispatch) {
  const q = query(doc(fbdb, "users", Auth.currentUser.uid));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    dispatch(setUserProfile(snapshot.data()));
  });
  return () => unsubscribe();
}

export function subsCardsCollection(dispatch, collections) {
  const q = query(collection(fbdb, collections.join("/")));
  const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const { doc, type } = change;
      switch (type) {
        case "added":
        case "modified":
          dispatch(setDatabyId({ id: doc.id, data: formatDocData(doc) }));
          break;
        case "removed":
          dispatch(removeDatabyId({ id: doc.id }));
          break;
        default:
          break;
      }
    });
  });
  return () => unsubscribe();
}

export function setDirectoryOfUser(dispatch, selectedProject, userProfile) {
  const path = `/projects/${selectedProject.value}/directory`;
  const { email, id } = userProfile;
  const q = query(collection(fbdb, path), where("members", "array-contains", { label: email, value: id }));
  const unsubscribe = onSnapshot(q, ({ docs }) => {
    if (docs.length) dispatch(setDirectory({ id: docs[0].id, ...docs[0].data() }));
    else dispatch(setDirectory({}));
  });
  return () => unsubscribe();
}

export function changeProjectInDb(project) {
  const q = query(doc(fbdb, "Users", Auth.currentUser.uid));
  updateDoc(q, { "selectedProject.value": project });
}

export async function addDocByCollectionArray(collections, data) {
  const { id } = await addDoc(collection(fbdb, collections.join("/")), data);
  return id;
}

export function setDocByCollectionArray(collections, data) {
  setDoc(doc(fbdb, collections.join("/")), data);
}

export async function getDocByPath(path) {
  const q = query(doc(fbdb, path));
  const result = await getDoc(q);
  return result.data();
}
