/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Auth from "../../services/firebase/Auth";
import { subsUserProfile } from "./../../services/firebase/DbUtils";
import { addUserMenu, removeUserMenu, setIsLoading } from "../../services/redux/AppUISlice";
import { selectedProjectRS, setUserLoggedIn, setAdmin, isAdminRS } from "../../services/redux/UserStateSlice";
import { isLoggedInRS } from "./../../services/redux/UserStateSlice";
import { serviceRS, setService } from "../../services/redux/AppUISlice";
import { directoryRS } from "../../services/redux/Project/selectors";

export function setupApp(dispatch) {
  // Stall to check if user is already logged in
  setTimeout(() => {
    dispatch(setIsLoading(false));
  }, 1000);

  // Listen to user login status
  Auth.onAuthStateChanged(async (user) => {
    dispatch(setUserLoggedIn(user !== null));
    user.getIdTokenResult().then((token) => {
      if (token.claims.isAdmin) dispatch(setAdmin(true));
      else dispatch(setAdmin(false));
    });
  });
}

export function useAppEffects() {
  const dispatch = useDispatch();
  const isAdmin = useSelector(isAdminRS);
  useEffect(() => {
    dispatch(setService({ link: window.location.pathname.split("/")[1], isAdmin }));
  }, []);
  const service = useSelector(serviceRS);

  useUserLogin(dispatch);
  useProjectSelect(dispatch);
  useNavigateToPath(dispatch, service);
}

function useUserLogin(dispatch) {
  const isLoggedIn = useSelector(isLoggedInRS);

  useEffect(() => {
    if (isLoggedIn) return subsUserProfile(dispatch);
  }, [isLoggedIn]);
}

function useProjectSelect(dispatch) {
  const selectedProject = useSelector(selectedProjectRS);
  const directory = useSelector(directoryRS);

  useEffect(() => {
    if (selectedProject) {
      if (directory.InsW || directory.InsR) dispatch(addUserMenu("inspections"));
      else dispatch(removeUserMenu("inspections"));
    }
  }, [selectedProject, directory]);
}

function useNavigateToPath(dispatch, service) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(service);
  }, [service]);
}

// eslint-disable-next-line no-extend-native
Date.prototype.toShortFormat = function () {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = this.getDate();

  const monthIndex = this.getMonth();
  const monthName = monthNames[monthIndex];

  const year = this.getFullYear() - 2000;

  return `${day}-${monthName}-${year}`;
};
