import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import store from "./services/redux/store";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <Profiler id="Scot" onRender={onRenderCallback}> */}
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
    {/* </Profiler> */}
  </React.StrictMode>
);

// function onRenderCallback(
//   id, // the "id" prop of the Profiler tree that has just committed
//   phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//   actualDuration, // time spent rendering the committed update
//   baseDuration, // estimated time to render the entire subtree without memoization
//   startTime, // when React began rendering this update
//   commitTime, // when React committed this update
//   interactions // the Set of interactions belonging to this update
// ) {
//   console.log({ id, phase, actualDuration, baseDuration, startTime, commitTime, interactions });
// }

serviceWorkerRegistration.register();
