import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import reducers from "./reducers";

export const ProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers,
});

export const {
  setAppInitialState,
  setDatabyId,
  setDirectory,
  setPaginationByKey,
  setModalByIdByKey,
  setFieldOnFormByKey,
  setProjectState,
  removeDatabyId,
  resetModalById,
  setModalById,
} = ProjectSlice.actions;

export default ProjectSlice.reducer;
