import { Fragment } from "react";
import FirebaseAuthScreen from "../../services/firebase/FirebaseAuthScreen";
import "./Login.css";

function Login() {
  return (
    <Fragment>
      <div className="login_form">
        <div className="login_form_container">
          <img src="/images/logo.png" alt="" />
          <span>Smart Contruction of Things</span>
          <FirebaseAuthScreen />
        </div>
      </div>
      <div className="login_bg"></div>
    </Fragment>
  );
}

export default Login;
