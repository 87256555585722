import { Timestamp } from "firebase/firestore";

function formatDocData(doc) {
  const isTimestamp = (value) => value instanceof Timestamp;
  const result = { id: doc.id, ...doc.data() };
  for (const key in result) {
    result[key] = isTimestamp(result[key]) ? toISOLocal(result[key].toDate()).slice(0, 16).replace("T", " ") : result[key];
  }
  return result;
}

function toISOLocal(d) {
  var z = (n) => ("0" + n).slice(-2);
  var zz = (n) => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
}

export { formatDocData};
