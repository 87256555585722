import { OAuthProvider, EmailAuthProvider, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getAuth } from "firebase/auth";

import App from "./App";

new OAuthProvider("microsoft.com");
export const uiConfig = {
  signInSuccessUrl: `#`,
  signInFlow: "popup",
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
    {
      provider: "microsoft.com",
    },
  ],
};

const Auth = getAuth(App);
setPersistence(Auth, browserLocalPersistence);

export default Auth;
