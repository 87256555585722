import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setupApp, useAppEffects } from "./components/utils/SetupApp";
import Router from "./services/router/Router";
import setFlavour from "./flavours/flavour";
import { ThemeProvider } from "@emotion/react";
import "./App.css";
import { Box, CssBaseline } from "@mui/material";
import Footer from "./components/Footer";
import { SnackbarProvider } from "notistack";

function App() {
  const [theme, setTheme] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setupApp(dispatch);
    setTheme(setFlavour());
    // eslint-disable-next-line
  }, []);

  useAppEffects();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
        <div className="App">
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Router />
          </Box>
          <Footer />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
