import { getFilteredData, getNoOfPaginationPages, getPaginatedData } from "../../../screens/CardsScreen/CardsScreen";

export const paginationRS = (state) => state.project.pagination;
export const collectionsRS = (state) => state.project.collections;
export const dataRS = (state) => state.project.data;
export const datumRS = (index) => (state) => state.project[index[0]][index[1]];
export const directoryRS = (state) => state.project.directory;
export const fieldOnFormRS = (state) => state.project.fieldOnForm;
export const fieldFilterRS = (state) => state.project.fieldFilter;
export const cardHeaderRS = (state) => state.project.cardHeader;
export const leftPanelRS = (state) => state.project.leftPanel;
export const modalDataRS = (type) => (state) => state.project.modal[type];
export const filteredDataRS = (state) => getFilteredData(state);
export const numberOfPagesRS = (state) => getNoOfPaginationPages(state);
export const paginatedDataRS = (state) => getPaginatedData(state);
