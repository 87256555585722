import ExcelJS from "exceljs";
import reportLogo from "../../assets/image/report.png";

function getBase64Image(img, dimensions) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const { width, height } = dimensions;

  canvas.width = width;
  canvas.height = height;
  context.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL("image/png");
}

function fillOneRow(row, data, textFormat) {
  data.forEach((value, index) => {
    let cell = row.getCell(index + 1);
    switch (value) {
      case "Closed":
        cell.style = { ...textFormat, fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FF32CD32" } } };
        break;
      case "Open":
        cell.style = { ...textFormat, fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFF4C4C" } } };
        break;
      default:
        cell.style = { ...textFormat };
        break;
    }
    cell.value = value;
  });
}

function getResizedDimensions(img, maxWidth, maxHeight) {
  const ratio = img.naturalWidth / img.naturalHeight;
  let width = maxWidth;
  let height = maxWidth / ratio;
  if (height > maxHeight) {
    height = maxHeight;
    width = height * ratio;
  }
  if (width > maxWidth) {
    width = maxWidth;
    height = width / ratio;
  }
  return { width, height };
}

function sortByField(obj, field) {
  return Object.keys(obj)
    .sort(function (a, b) {
      return obj[a][field] - obj[b][field];
    })
    .map(function (category) {
      return obj[category]; // Convert array of categories to array of objects
    });
}

function sortByStringField(obj, field) {
  return Object.keys(obj)
    .sort(function (a, b) {
      if (obj[a][field] < obj[b][field]) {
        return 1;
      }
      if (obj[a][field] > obj[b][field]) {
        return -1;
      }
      return 0;
    })
    .map(function (category) {
      return obj[category]; // Convert array of categories to array of objects
    });
}

function loadImageToBase64(imgUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = function () {
      reject(new Error("Failed to load image"));
    };
    img.src = imgUrl;
  });
}

const downloadExcel = async (filteredData, selectedProject, filename) => {
  const workbook = new ExcelJS.Workbook();
  const options = {
    pageSetup: {
      paperSize: 9,
      orientation: "landscape",
      margins: { left: 0.7, right: 0.7, top: 0.55, bottom: 0.55, header: 0.3, footer: 0.3 },
    },
  };
  const worksheet = workbook.addWorksheet("Report", options);
  const maxWidth = 300;
  const maxHeight = 300;
  const textFormats = {
    f1: { font: { bold: true } },
    f2: { font: { bold: true }, border: { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } } },
    f3: {
      alignment: { wrapText: true, vertical: "top", horizontal: "left" },
      border: { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } },
    },
  };

  worksheet.properties.defaultRowHeight = 15;
  worksheet.properties.defaultColWidth = 38;
  worksheet.headerFooter.oddFooter = `&LGenerated by SCOT platform on ${new Date().toDateString()}&RPage &P of &N`;

  let cell = worksheet.getCell("A1");
  cell.style = { ...textFormats.f1 };
  cell.value = `Project: ${selectedProject.label}`;

  cell = worksheet.getCell("A2");
  cell.style = { ...textFormats.f1 };
  cell.value = "Quality Report";

  cell = worksheet.getCell("A3");
  cell.style = { ...textFormats.f1 };
  cell.value = "Date Generated: " + new Date().toDateString();

  const base64Logo = await loadImageToBase64(reportLogo);
  const logoId = workbook.addImage({
    base64: base64Logo,
    extension: "png",
  });
  worksheet.addImage(logoId, { tl: { col: 4.9, row: 0.1 }, ext: { height: 48, width: 130 } });

  worksheet.columns = [{ width: 8.29 }, { width: 45 }, { width: 26 }, { width: 12 }, { width: 38 }];

  fillOneRow(worksheet.getRow(5), ["Number", "Image", "Description", "Status", "Details"], textFormats.f2);

  const sortedArray = sortByField(filteredData, "number");

  sortedArray.forEach((elem, index) => {
    const { fromRolechip, toRolechip, ccRolechip, title, details, number, location, dateExpected, statusOC, fileName, dateCreated, id } = elem;
    if (!toRolechip) {
      // M.toast({ html: "TO field is not defined for inspection " + number, classes: "rounded" });
    }
    worksheet.getRow(6 + index).height = 50;
    fillOneRow(
      worksheet.getRow(6 + index),
      [
        number,
        "",
        `${title || ""}\n${details || ""}\n${location ? "Location: " + location: ""}`,
        statusOC,
        `Created by: ${fromRolechip[0].label} on ${dateCreated}\r\nAction by: ${toRolechip
          .map((e) => e.label)
          .join(",")} upto ${dateExpected}\r\ncc: ${ccRolechip ? ccRolechip.map((e) => e.label).join(",") : ""}`,
      ],
      textFormats.f3
    );
    if (fileName) {
      const img = document.getElementById(`img-${id}`);
      const resizedDimensions = getResizedDimensions(img, maxWidth, maxHeight);
      worksheet.getRow(6 + index).height = Math.max(Math.round(resizedDimensions.height * 0.8), 77);
      const base64Image = getBase64Image(img, resizedDimensions);
      const imageId = workbook.addImage({
        base64: base64Image,
        extension: "png",
      });
      worksheet.addImage(imageId, { tl: { col: 1.05, row: index + 5.05 }, ext: resizedDimensions });
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  var a = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
  a.click();
};

export { downloadExcel, sortByStringField };
