import "./Preloader.css";
import { useSelector } from "react-redux";
import { isLoadingRS } from "../../services/redux/AppUISlice";

function Preloader() {
  const isLoading = useSelector(isLoadingRS);

  if (isLoading) {
    return (
      <div className="preloader-wrapper small active">
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Preloader;
