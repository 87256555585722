import { createSlice } from "@reduxjs/toolkit";

export const AppUISlice = createSlice({
  name: "AppUI",
  initialState: {
    isLoading: true,
    sideBarOpen: false,
    currentPath: [],
    service: "dashboard",
    appsMenu: {
      inspections: { link: "inspections", label: "Inspections", icon: "search" },
    },
    userMenu: {
      dashboard: { link: "dashboard", label: "Dashboard", icon: "dashboard" },
      directory: { link: "directory", label: "Project Directory", icon: "group" },
    },
    adminMenu: {
      projects: { link: "projects", label: "Projects List", icon: "insert_drive_file" },
      users: { link: "users", label: "Users Management", icon: "person" },
      companies: { link: "companies", label: "Companies", icon: "corporate_fare" },
    },
    modal: {
      modalAddFillData: { open: false, error: {} },
      modalEditFillData: { open: false, error: {} },
      modalFilterFillData: { open: false, error: {} },
    },
  },
  reducers: {
    addUserMenu(state, { payload }) {
      state.userMenu[payload] = state.appsMenu[payload];
    },
    removeUserMenu(state, { payload }) {
      delete state.userMenu[payload];
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSideBarOpen(state, { payload }) {
      state.sideBarOpen = payload;
    },
    setModalOpen(state, { payload }) {
      const { modalType, open } = payload;
      state.modal[modalType].error = {};
      state.modal[modalType].open = open;
    },
    setModalError(state, { payload }) {
      const { modalType, key, error } = payload;
      state.modal[modalType].error[key] = error;
    },
    setService(state, { payload }) {
      const { link, isAdmin } = payload;
      let menus = [];
      if (isAdmin) menus = { ...state.userMenu, ...state.adminMenu };
      else menus = state.userMenu;

      if (menus[link]) state.service = menus[link].link;
      else state.service = "dashboard";
    },
  },
});

export const { addUserMenu, setIsLoading, setSideBarOpen, setService, removeUserMenu, setModalOpen, setModalError } = AppUISlice.actions;

export const sideBarOpenRS = (state) => state.appUI.sideBarOpen;
export const isLoadingRS = (state) => state.appUI.isLoading;
export const userMenuRS = (state) => state.appUI.userMenu;
export const adminMenuRS = (state) => state.appUI.adminMenu;
export const serviceRS = (state) => state.appUI.service;
export const modalRS = (type) => (state) => state.appUI.modal[type];
export const openModalRS = (state) => Object.keys(state.appUI.modal).filter((e) => state.appUI.modal[e].open)[0];

export default AppUISlice.reducer;
