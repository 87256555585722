const initialState = {
  data: {},
  filteredData: {},
  paginatedData: {},
  fieldOnForm: {},
  fieldFilter: {},
  modal: {
    modalEditFillData: {},
    modalFilterFillData: {
      from: new Date(2023, 0, 2).toISOString().substring(0, 10),
      to: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
    },
    modalAddFillData: {},
  },
  collections: [],
  cardHeader: {},
  fileContainer: {},
  pagination: {
    currentPage: 1,
    itemsperPage: 20,
  },
  sortField: "dateModified",
  directory: {},
};

export default initialState;
