import initialState from "./initialState";

const reducers = {
  setAppInitialState(state, { payload }) {
    const { directory } = state;
    const data = {};
    return { ...initialState, directory: directory, ...payload, data };
  },
  setProjectState(state, { payload }) {
    state.projectState = { ...payload };
  },
  resetModalById(state, { payload }) {
    state.modal[payload] = { ...state.projectState.modal[payload] };
  },
  setFieldOnFormByKey(state, { payload }) {
    const { fields, data } = payload;
    state[fields[0]][fields[1]][fields[2]]= data;
  },
  setModalById(state, { payload }) {
    const { id, data } = payload;
    state.modal[id] = data;
  },
  setModalByIdByKey(state, { payload }) {
    const { id, key, data } = payload;
    state.modal[id][key] = data;
  },
  setDatabyId(state, { payload }) {
    const { id, data } = payload;
    state.data[id] = data;
  },
  removeDatabyId(state, { payload }) {
    delete state.data[payload.id];
  },
  setDirectory(state, { payload }) {
    state.directory = payload;
  },
  setPaginationByKey(state, { payload }) {
    const { key, data } = payload;
    state.pagination[key] = data;
  },
};

export default reducers;
