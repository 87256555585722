import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
// import Box from "@mui/material/Box";

import Login from "../../screens/Login/Login";
import { isLoggedInRS } from "../redux/UserStateSlice";
import { isLoadingRS } from "../redux/AppUISlice";
import Preloader from "../../components/utils/Preloader";

const AppScreen = lazy(() => import("./../../screens/AppScreen/AppScreen"));

function Router() {
  const isLoggedIn = useSelector(isLoggedInRS);
  const isLoading = useSelector(isLoadingRS);

  return (
    <>
      {isLoggedIn ? (
        <Suspense fallback={<Preloader />}>
          <AppScreen />
        </Suspense>
      ) : (
        <>{isLoading ? <Preloader /> : <Login />}</>
      )}
    </>
  );
}

export default Router;
