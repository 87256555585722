// import "./Footer.css";
import { Box, Typography } from "@mui/material";
import packageJson from "../../package.json";
import { footerHeight } from "../services/mui/settings";

const Footer = () => {
  return (
    <Box
      height={footerHeight}
      position={"relative"}
      bottom={0}
      right={2}
      sx={{
        justifyContent: "flex-end",
        display: "flex",
        mb: 0.5,
        mt: 0.5,
        mr: 1,
      }}
    >
      <Typography variant="caption" color="initial">
        Copyright ©2024 - Digiconsult AA Ltd - SCOT Platform V{packageJson.version}
      </Typography>
    </Box>
  );
};

export default Footer;
