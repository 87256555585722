import { initializeApp } from 'firebase/app';

let firebaseConfig = {
  apiKey: "AIzaSyD2BpICSlgARTBQkgJnjfY4boZ4ej39J4o",
  authDomain: "scot.ec3.tech",//"scot-dev-210417.firebaseapp.com",
  projectId: "scot-dev-210417",
  storageBucket: "scot-dev-210417.appspot.com",
  messagingSenderId: "431699358349",
  appId: "1:431699358349:web:da1489feed728ecbc868c4",
  measurementId: "G-SM818FM4KP"
};

const App = initializeApp(firebaseConfig);

export default App;



