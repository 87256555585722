import { createSlice } from "@reduxjs/toolkit";

export const UserStateSlice = createSlice({
  name: "userstate",
  initialState: {
    isLoggedIn: false,
    isAdmin: false,
    profile: {},
  },
  reducers: {
    setAdmin(state, actions) {
      state.isAdmin = actions.payload;
    },
    setUserLoggedIn(state, actions) {
      state.isLoggedIn = actions.payload;
    },
    setUserProfile(state, actions) {
      state.profile = { ...actions.payload };
    },
  },
});

export const { setAdmin, setUserLoggedIn, setUserProfile } = UserStateSlice.actions;

export const isLoggedInRS = (state) => state.userState.isLoggedIn;
export const isAdminRS = (state) => state.userState.isAdmin;
// export const projectsRS = (state) => state.userState.profile.Projects;
export const selectedProjectRS = (state) => state.userState.profile?.selectedProject;
export const userProfileRS = (state) => state.userState.profile;

export default UserStateSlice.reducer;
