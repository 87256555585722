import { initializeFirestore, CACHE_SIZE_UNLIMITED, enableMultiTabIndexedDbPersistence } from "firebase/firestore";

import App from "./App";
// import { ErrorHandler, getName } from "../ErrorHandler";

// const fileForError = "services => db";

function initializeDb() {
  const result = initializeFirestore(App, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    persistence: true,
  });
  enableMultiTabIndexedDbPersistence(result);

  return result;
}

const fbdb = initializeDb();

export default fbdb;
