import { createTheme } from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";

export const flavour = getFlavour('scot');

function getFlavour(client) {
  const flavours = {
    scot: {
      pageTitle: "Smart Construction",
      favicon: `${window.location.origin}/flavours/ec3/logo.svg`,
      brandlogo1: "./images/icons/icon-48x48.png",
      brandlogo2: "./images/logo.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003a70", contrastText: "#fff" },
          secondary: { main: "#00adee", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/ec3/logo.svg",
        background: "/flavours/ec3/login_bg.jpg",
        loginText: "EC3 Platform",
      },
    },
  };
  return flavours[client];
}

function setFlavour() {
  // Set HTML title
  document.title = flavour.pageTitle;

  // Set Favicon
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = flavour.favicon;

  // Set Theme
  const theme = createTheme({
    palette: flavour.palette.light,
    typography: flavour.typography,
  });

  return theme;
}

export default setFlavour;
